.blog__heading {
  width: 90%;
  margin: 1rem 0;
  height: fit-content;
  border-radius: 12px;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.3rem 0;
  text-align: center;
  color: white;
  background-color: var(--primary-color);
}

.blog__scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}

.blog__container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  background-color: var(--gray200);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.blog__posterContainer {
  display: flex;
  align-items: center;
}

.blog__poster {
  max-width: 200px;
  max-height: 200px;
  height: auto;
  width: auto;
  object-fit: contain;
}

.blog__detailsContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
  width: 100%;
}

.blog__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog__detailsName {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.blog__detailsDesc {
  text-align: justify;
  text-justify: inter-word;
}

.blog__detailsButtonContainer {
  display: flex;
}

.blog__detailsButtons {
  min-width: 100px;
  text-align: center;
  padding: 0.5rem;
  margin: 0 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 0.5rem;
  color: black;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.blog__detailsButtons:hover {
  color: white !important;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.blog__detailsDesc {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .blog__heading {
    margin-top: 140px;
  }
}

@media screen and (max-width: 840px) {
  .blog__detailsName,
  .blog__detailsDesc {
    margin: 0.5rem;
  }

  .blog__details {
    display: flex;
    flex-direction: column;
  }

  .blog__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blog__posterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
