.content__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray100);
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .content__container {
    width: 100%;
  }
}
