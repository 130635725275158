.project__container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  background-color: var(--gray200);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.project__posterContainer {
  display: flex;
  align-items: center;
}

.project__poster {
  max-width: 200px;
  max-height: 160px;
  height: auto;
  width: auto;
  object-fit: contain;
}

.project__detailsContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
  width: 100%;
}

.project__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project__detailsName {
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  gap: 5px;
}

.project__detailsName span {
  font-size: 1.2rem;
  font-weight: 400;
}

.project__detailsDesc {
  text-align: justify;
  text-justify: inter-word;
}

.project__detailsDesc a {
  color: var(--primary-color-dark);
  font-weight: 500;
}

.project__detailsButtonContainer {
  display: flex;
}

.project__detailsButtons {
  font-weight: 500;
  min-width: 110px;
  padding: 0.5rem;
  margin: 0 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 0.5rem;
  text-align: center;
  color: black;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.project__detailsButtons:hover {
  color: white !important;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.project__detailsDesc {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 840px), screen and (max-height: 720px) {
  .project__detailsName,
  .project__detailsDesc {
    margin: 0.5rem;
  }

  .project__details {
    display: flex;
    flex-direction: column;
  }

  .project__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project__posterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
