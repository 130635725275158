@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-family: "Montserrat", sans-serif;
  --primary-color-dark: #0080ff;
  --primary-color: #0099ff;
  --secondary-color: #66ccff;
  --gray50: #f9fafb;
  --gray100: #f3f4f6;
  --gray200: #e5e7eb;
  --gray300: #d1d5db;
  --gray400: #9ca3af;
  --gray500: #6b7280;
  --gray600: #4b5563;
  --gray700: #374151;
  --gray800: #1f2937;
  --gray900: #111827;
  background-image: linear-gradient(
    to right,
    #0099ff,
    #f3f4f6);
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--gray100)
  );
}

.App {
  font-family: "Montserrat", sans-serif;
  width: 100vw;
  height: 100vh;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  margin: auto;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  :root {
    background-image: none;
    background-color: #f3f4f6;
    background-color: var(--gray100);
  }

  .App {
    flex-direction: column;
  }
}

.content__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray100);
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .content__container {
    width: 100%;
  }
}

.project__container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  background-color: var(--gray200);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.project__posterContainer {
  display: flex;
  align-items: center;
}

.project__poster {
  max-width: 200px;
  max-height: 160px;
  height: auto;
  width: auto;
  object-fit: contain;
}

.project__detailsContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
  width: 100%;
}

.project__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project__detailsName {
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.project__detailsName span {
  font-size: 1.2rem;
  font-weight: 400;
}

.project__detailsDesc {
  text-align: justify;
  text-justify: inter-word;
}

.project__detailsDesc a {
  color: var(--primary-color-dark);
  font-weight: 500;
}

.project__detailsButtonContainer {
  display: flex;
}

.project__detailsButtons {
  font-weight: 500;
  min-width: 110px;
  padding: 0.5rem;
  margin: 0 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 0.5rem;
  text-align: center;
  color: black;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.project__detailsButtons:hover {
  color: white !important;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.project__detailsDesc {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 840px), screen and (max-height: 720px) {
  .project__detailsName,
  .project__detailsDesc {
    margin: 0.5rem;
  }

  .project__details {
    display: flex;
    flex-direction: column;
  }

  .project__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project__posterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.portfolio__selector {
  width: 90%;
  min-height: 40px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--gray300);
  display: flex;
  justify-content: space-around;
  border-radius: 12px;
  font-size: 1.5rem;
  cursor: pointer;
}

.portfolio__selectorOption {
  width: 50%;
  font-weight: 700;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  color: white;
  border-radius: 12px;
  background-color: var(--primary-color);
}

.portfolio__projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .portfolio__selector {
    margin-top: 140px;
  }
}

.blog__heading {
  width: 90%;
  margin: 1rem 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 12px;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.3rem 0;
  text-align: center;
  color: white;
  background-color: var(--primary-color);
}

.blog__scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}

.blog__container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  background-color: var(--gray200);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.blog__posterContainer {
  display: flex;
  align-items: center;
}

.blog__poster {
  max-width: 200px;
  max-height: 200px;
  height: auto;
  width: auto;
  object-fit: contain;
}

.blog__detailsContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
  width: 100%;
}

.blog__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog__detailsName {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.blog__detailsDesc {
  text-align: justify;
  text-justify: inter-word;
}

.blog__detailsButtonContainer {
  display: flex;
}

.blog__detailsButtons {
  min-width: 100px;
  text-align: center;
  padding: 0.5rem;
  margin: 0 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 0.5rem;
  color: black;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.blog__detailsButtons:hover {
  color: white !important;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.blog__detailsDesc {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .blog__heading {
    margin-top: 140px;
  }
}

@media screen and (max-width: 840px) {
  .blog__detailsName,
  .blog__detailsDesc {
    margin: 0.5rem;
  }

  .blog__details {
    display: flex;
    flex-direction: column;
  }

  .blog__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blog__posterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.sidebar__container {
  width: 20%;
  height: 100%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.sidebar__logoContainer {
  width: 80%;
  margin: 1rem 0;
  text-align: center;
}

.sidebar__logo {
  max-width: 100%;
}

.sidebar__burgerButton {
  width: 0px;
  height: 0px;
}

.sidebar__burgerContent {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sidebar__profilePicContainer {
  width: 80%;
  margin: 1rem 0;
  text-align: center;
}

.sidebar__profilePic {
  max-width: 100%;
  max-height: 200px;
  border-radius: 50%;
}

.fadeIn {
  animation: fade 0.9s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}

.sidebar__introTextContainer {
  width: 80%;
  text-align: center;
  margin-bottom: 1rem;
}

.sidebar__introText {
  font-size: 1.5rem;
}

.sidebar__introText h3 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-bottom: 0.5rem;
}

.sidebar__nav {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.sidebar__navButton {
  width: 100%;
  max-width: 200px;
  border: 1px solid;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}

.sidebar__navButton:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
  transition: background-color 0.2s ease-in-out;
}

.sidebar__nav > .selected {
  pointer-events: none;
  background-color: var(--secondary-color);
}

.sidebar__social {
  margin: 1rem 0;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 2rem;
}

a {
  color: white;
  margin: 0 0.2rem;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: black;
  transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .sidebar__container {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    flex-direction: row;
    margin-bottom: 100px;
  }

  .sidebar__logoContainer {
    margin: 0 1rem;
    max-width: 140px;
    z-index: -1;
  }

  .sidebar__burgerButton {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 2rem;
    font-size: 3rem;
  }

  .visible {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: right 0.2s ease-in-out;
  }

  .invisible {
    position: fixed;
    right: -110%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: right 0.2s ease-in-out;
  }

  .sidebar__nav {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 504px) {
  .sidebar__heroTitle {
    font-size: 1.5rem;
  }

  .sidebar__introText {
    font-size: 1rem;
  }
}

