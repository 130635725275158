@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

:root {
  font-family: "Montserrat", sans-serif;
  --primary-color-dark: #0080ff;
  --primary-color: #0099ff;
  --secondary-color: #66ccff;
  --gray50: #f9fafb;
  --gray100: #f3f4f6;
  --gray200: #e5e7eb;
  --gray300: #d1d5db;
  --gray400: #9ca3af;
  --gray500: #6b7280;
  --gray600: #4b5563;
  --gray700: #374151;
  --gray800: #1f2937;
  --gray900: #111827;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--gray100)
  );
}

.App {
  font-family: "Montserrat", sans-serif;
  width: 100vw;
  height: 100vh;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  margin: auto;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  :root {
    background-image: none;
    background-color: var(--gray100);
  }

  .App {
    flex-direction: column;
  }
}
