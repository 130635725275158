.portfolio__selector {
  width: 90%;
  min-height: 40px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--gray300);
  display: flex;
  justify-content: space-around;
  border-radius: 12px;
  font-size: 1.5rem;
  cursor: pointer;
}

.portfolio__selectorOption {
  width: 50%;
  font-weight: 700;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  color: white;
  border-radius: 12px;
  background-color: var(--primary-color);
}

.portfolio__projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .portfolio__selector {
    margin-top: 140px;
  }
}
