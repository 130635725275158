.sidebar__container {
  width: 20%;
  height: 100%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.sidebar__logoContainer {
  width: 80%;
  margin: 1rem 0;
  text-align: center;
}

.sidebar__logo {
  max-width: 100%;
}

.sidebar__burgerButton {
  width: 0px;
  height: 0px;
}

.sidebar__burgerContent {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sidebar__profilePicContainer {
  width: 80%;
  margin: 1rem 0;
  text-align: center;
}

.sidebar__profilePic {
  max-width: 100%;
  max-height: 200px;
  border-radius: 50%;
}

.fadeIn {
  animation: fade 0.9s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}

.sidebar__introTextContainer {
  width: 80%;
  text-align: center;
  margin-bottom: 1rem;
}

.sidebar__introText {
  font-size: 1.5rem;
}

.sidebar__introText h3 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 0.5rem;
}

.sidebar__nav {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.sidebar__navButton {
  width: 100%;
  max-width: 200px;
  border: 1px solid;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}

.sidebar__navButton:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
  transition: background-color 0.2s ease-in-out;
}

.sidebar__nav > .selected {
  pointer-events: none;
  background-color: var(--secondary-color);
}

.sidebar__social {
  margin: 1rem 0;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 2rem;
}

a {
  color: white;
  margin: 0 0.2rem;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: black;
  transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 1040px), screen and (max-height: 720px) {
  .sidebar__container {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    flex-direction: row;
    margin-bottom: 100px;
  }

  .sidebar__logoContainer {
    margin: 0 1rem;
    max-width: 140px;
    z-index: -1;
  }

  .sidebar__burgerButton {
    width: fit-content;
    height: fit-content;
    margin-right: 2rem;
    font-size: 3rem;
  }

  .visible {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: right 0.2s ease-in-out;
  }

  .invisible {
    position: fixed;
    right: -110%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: right 0.2s ease-in-out;
  }

  .sidebar__nav {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 504px) {
  .sidebar__heroTitle {
    font-size: 1.5rem;
  }

  .sidebar__introText {
    font-size: 1rem;
  }
}
